import React, { useEffect , useRef }from 'react'
import { init } from 'ityped'
import Introduction from '../../images/introduction2.jpg'
import Downloads from './Downloads';
import Marquee from './Marquee';

/**
* Bemutatkozás

* Rövid bemutatkozás, kapcsolati linkkel és 
* önéletrajz letöltési lehetőséggel
@returns {React.Component} - Introduction componens
* * Validálva
*/
function SecondPageIntroduction() {
    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
            showCursor: true,
            backDelay: 2000,
            backSpeed: 60,
            strings: ["Frontend fejlesztő" , "Webdesigner"],
        })
    })
    return (
    <section className="container firstBackground pb-2"
            id="introduction">
        <div className="row">
            <div className='col-12 col-lg-7 introduction 
                        order-2 order-lg-1 pb-2 pt-sm-5 ps-sm-5'>
                <h1 
                    className='ps-lg-5 mt-0 mt-lg-5 pt-0 pt-lg-5 pb-sm-3 
                                text-center fs-1 text'>
                    Rehák Tamás | portfólió
                </h1>
                <h4
                    className='ps-lg-5 pb-3 fs-4 text-center'>
                    <span ref={textRef}></span>
                </h4>
                <p 
                    className='ps-lg-5 pt-3 fs-5'>
                    Rehák Tamás vagyok, szenvedéllyel és elkötelezettséggel 
                    rendelkező webfejlesztő.
                </p>
                <p 
                    className='ps-lg-5 pt-3 fs-5'>
                    <i className="fa-solid fa-laptop-code pe-2"></i>
                    Multimédia – fejlesztő és Webdesigner bizonyítványom 
                    mellett önállóan tanultam meg a webprogramozás művészetét, 
                    és jelenleg is folyamatosan bővítem tudásomat.
                </p>
                <div 
                    className='d-flex align-items-center 
                            justify-content-center mb-3'>
                    <a href="#contact" className='text-decoration-none'>
                    <button className="contact-button
                                    ms-4 mt-3 me-2 mb-3">
                        <div className="icon">
                            <i className="fas fa-envelope 
                                        shere pt-1"></i>
                            <i className="fas fa-envelope icon-shere">
                            </i>
                            <i className="fas fa-chevron-down 
                                        fa-fade icon-shere">
                            </i>
                            <i className="fas fa-mobile-screen icon-shere">
                            </i>
                        </div>
                        <p className='d-flex align-items-center 
                                    fw-bolder mb-0'>
                            Kapcsolat
                        </p>
                    </button>
                    </a>
                    <a href="https://github.com/TommyLoop"
                        className='d-flex align-items-center 
                                    text-decoration-none'
                        target="_blank"
                        rel="noreferrer">
                        <i className="fs-3 px-2 fa-brands fa-github"></i>
                        Github
                    </a>
                    <a href="https://www.linkedin.com/in/tamas-rehak/"
                        className='d-flex align-items-center 
                                    text-decoration-none'
                        target="_blank"
                        rel="noreferrer">
                        <i className="fs-3 px-2 fa-brands fa-linkedin"></i>
                        Linkedin
                    </a>
                </div>
            <Downloads />
            </div>
            <div className='col-12 col-lg-5 order-1 order-lg-2 
                            d-flex d-lg-block justify-content-end 
                            align-items-top px-0'>
                <img 
                    src={Introduction}
                    height="500"
                    width="650"
                    loading='lazy'
                    title='Introduction pictures'
                    className="img-fluid" 
                    alt="Introduction pictures"/>
                </div>   
        </div>
        <Marquee />
        <div id="skills"></div>
    </section>
)
}

export default SecondPageIntroduction
