import React, {useEffect } from 'react'
import ExperienceImage from '../../images/experience.jpg'
import ProgrammerDesk from '../../images/experience.png'
import Game from '../../images/game.jpg'
import Viragtunder from '../../images/carousel.jpg'
import WeatherApp from './WeatherApp'
import Aos from 'aos'
import 'aos/dist/aos.css'

/**
* Tapasztalatok

* Kisebb nagyobb projektek bemutatása
@returns {React.Component} - Experience componens
* * Validálva
*/
function Experience() {
    useEffect(()=> {
        Aos.init();
    }, [])
return (
    <section className="container">
        <div className='row firstBackground d-flex align-items-center'>
            <div className='d-none d-lg-block col-6 p-0'>
                <div className="rel">
                    <img 
                        height="1400"
                        width="660"
                        loading='lazy'
                        title='experience'
                        className='img-fluid'
                        src={ExperienceImage}
                        alt="experience"/> 
                    <div 
                        className="abs"
                        data-aos="fade-right"
                        data-aos-duration="1000">
                        <img
                            height="1300"
                            width="660"
                            loading='lazy'
                            title='ProgrammerDesk'
                            className='img-fluid'
                            src={ProgrammerDesk}
                            alt="ProgrammerDesk"/> 
                    </div>
                </div>
            </div>
            <div className='col-lg-6 row p-0 d-flex justify-content-center'>
                <div className="col-10 px-2 text-center" >
                    <h3 className="experience-title parallelogram mb-2">
                        Tapasztalatok
                    </h3>
                    <p className='d-flex align-items-center 
                                    justify-content-center'>
                        <a 
                            href="https://github.com/TommyLoop/"
                            className='d-flex align-items-center 
                                    text-decoration-none'
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="display-5 fab fa-github p-2"></i>
                            Github
                        </a>
                    </p>
                    <h5 className='pt-3'>
                        Virágtündér műhelye webshop
                    </h5>
                    <a 
                        className="card hover-zoom border-shadow p-3"
                        data-aos="flip-up"
                        data-aos-duration="1000"
                        href="https://rt-teszt-hu.hu" 
                        target="_blank"
                        rel="noreferrer">
                            <img
                                height="457"
                                width="1200"
                                loading='lazy'
                                title='Viragtunder'
                                className="card-img-top" 
                                src={Viragtunder} 
                                alt="Viragtunder"
                            />
                    </a>
                    <h5 className='pt-4'>
                        Autós játék
                    </h5>
                    <a 
                        className="card hover-zoom border-shadow my-2"
                        data-aos="flip-up"
                        data-aos-duration="1000"
                        href="https://tommyloop1983-gmail-com.trinket.io/sites/keruldkiazautokat" 
                        target="_blank"
                        rel="noreferrer">
                            <img
                                height="218"
                                width="572"
                                loading='lazy'
                                title='Game'
                                className="card-img-top" 
                                src={Game} 
                                alt="Game"
                            />
                    </a>
                    <h5 className='pt-4'>
                        Időjárás app
                    </h5>
                    <div 
                        data-aos="flip-down" 
                        data-aos-duration="1000">
                    <WeatherApp />
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    )
}

export default Experience
