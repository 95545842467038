import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'

/**
* Szaktudás

* Kis illusztráció a szakmai tudásról
@returns {React.Component} - Skills componens
* * Validálva
*/
function SecondPageSkills() {
  useEffect(()=> {
    Aos.init();
  }, [])
  return (
    <section className="container mx-0">
    <div className="row firstBackground skills pt-2 p-0">
      <div className="col-12 p-5 text-center" >
        <div className="parallelogram">
          <h3 className="skills-title">Szaktudás</h3>
        </div>
      </div>
        <h4 className="p-2 webdesigner ps-5 pb-2">
          <i className="fa-solid fa-palette pe-2"></i>
            Jelenlegi tudásom a frontend területén:
        </h4>
        <div className="col-md-4 col-lg-2 d-flex align-items-center 
                        justify-content-end ">
          <i className="fas fa-diamond skills-diamond1 
                        d-none d-md-block 
                        pe-lg-0 pe-xl-3 pe-xxl-5"></i>
        </div>
        <div 
          className="col-md-8 col-lg-10
                      text-start ps-5 ps-xl-1 pb-5"
          data-aos="flip-down"
          data-aos-duration="2000">
          <p>
            <i className="fab fa-html5 pe-2"></i>HTML: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-css3-alt pe-2"></i>CSS: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-sass pe-2"></i>SASS: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-bootstrap pe-2"></i>BOOTSTRAP: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-js-square pe-2"></i>JAVASCRIPT: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
        </div>
          <h4 className="p-2 frontend pe-5 pb-2">
            <i className="fa-solid fa-laptop-code pe-2"></i>
              Modern JS könyvtárak és keretrendszerek:
          </h4>
        <div className="col-md-4 col-lg-5 col-xl-4 d-flex align-items-center
                        justify-content-end pe-lg-5 pe-xl-0">
          <i className="fas fa-diamond skills-diamond2
                        d-none d-md-block"></i>
        </div>
        <div 
          className="col-md-8 col-lg-7 col-xl-8 
                      ps-5 ps-lg-0 ps-xl-5 pb-5
                      text-start"
          data-aos="flip-down"
          data-aos-duration="2000">
          <p>
            <i className="fab fa-react pe-2"></i>REACT: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="devicon-redux-original pe-2"></i>REDUX: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline'>UI tervezés: </span>
            <i className="devicon-materialui-plain px-2"></i>Material UI
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline pe-2'>Űrlapkezelés </span>
            Formik, Yup
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline'>Adatkommunikáció:</span>
            <i className="devicon-axios-plain px-2"></i>Axios
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline'>Navigáció:</span>
            <i className="devicon-reactrouter-plain px-2"></i>React Router DOM
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline'>Verziókezelés</span>
            <i className="devicon-github-original px-2"></i>GitHub
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <span className='text-decoration-underline'>Konténerizáció:</span>
            <i className="devicon-docker-plain px-2"></i>Docker
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
        </div>
          <h4 className="backend pb-2">
            <i className="fa-solid fa-shield-halved pe-2"></i>
              Backend területen:
          </h4>
        <div className="col-md-4 col-lg-7 d-flex align-items-center 
                        justify-content-end">
          <i className="fas fa-diamond d-none d-md-block 
                        skills-diamond3 pe-xl-5"></i>
        </div>
        <div 
          className="col-md-8 col-lg-5 text-start 
                    ps-5 ps-xl-4 pb-5"
          data-aos="flip-down"
          data-aos-duration="2000">
          <p>
            <i className="devicon-mysql-original pe-2"></i>MySQL: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="devicon-postman-plain pe-2"></i>POSTMAN: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-php pe-2"></i>PHP: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
          <p>
            <i className="fab fa-node-js pe-2"></i>NODEJS: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
          <p>
            <i className="devicon-python-plain pe-2"></i>PYTHON: 
            <i className="fas fa-square-full ps-3 pe-1"></i>
            <i className="fas fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
            <i className="far fa-square-full p-1"></i>
          </p>
        </div>
    <div className="pb-lg-5" id="experience"></div>
    </div>
    </section>
  )
}

export default SecondPageSkills
