import React from 'react';
import './Marquee.css'; // Hivatkozzunk a CSS fájlra

const Marquee = () => {
    return (
    <div className="marquee mx-auto">
        <div className="marquee-content">
            <div><i className="devicon-html5-plain-wordmark pe-4"></i></div>
            <div><i className="devicon-css3-plain-wordmark px-4"></i></div>
            <div><i className="devicon-bootstrap-plain-wordmark px-4"></i></div>
            <div><i className="devicon-sass-original px-4"></i></div>
            <div><i className="devicon-javascript-plain px-4"></i></div>
            <div><i className="devicon-react-original-wordmark px-4"></i></div>
            <div><i className="devicon-materialui-plain px-4"></i></div>
            <div><i className="devicon-axios-plain px-4"></i></div>
            <div><i className="devicon-redux-original px-4"></i></div>
            <div><i className="devicon-mysql-original px-4"></i></div>
            <div><i className="devicon-postman-plain px-4"></i></div>
            <div><i className="devicon-php-plain px-4"></i></div>
            <div><i className="devicon-npm-original-wordmark px-4"></i></div>
            <div><i className="devicon-github-original px-4"></i></div>
            <div><i className="devicon-docker-plain px-4"></i></div>
            <div><i className="devicon-html5-plain-wordmark pe-4"></i></div>
            <div><i className="devicon-css3-plain-wordmark px-4"></i></div>
            <div><i className="devicon-bootstrap-plain-wordmark px-4"></i></div>
            <div><i className="devicon-sass-original px-4"></i></div>
            <div><i className="devicon-javascript-plain px-4"></i></div>
            <div><i className="devicon-react-original-wordmark px-4"></i></div>
            <div><i className="devicon-materialui-plain px-4"></i></div>
            <div><i className="devicon-axios-plain px-4"></i></div>
            <div><i className="devicon-redux-original px-4"></i></div>
            <div><i className="devicon-mysql-original px-4"></i></div>
            <div><i className="devicon-postman-plain px-4"></i></div>
            <div><i className="devicon-php-plain px-4"></i></div>
            <div><i className="devicon-npm-original-wordmark px-4"></i></div>
            <div><i className="devicon-github-original px-4"></i></div>
            <div><i className="devicon-docker-plain px-4"></i></div>
            
        </div>
    </div>
    );
}

export default Marquee;
