import React from 'react'
import ImageGallery from 'react-image-gallery';
import '../../index.css'

/**
* Képek listája

* Fényképek melyeket sikerült lencsevégrekanom
* * Validálva
*/
const images = [
    {
        original: require('../../images/photos/005.jpg'),
        thumbnailAlt: 'fa',
        thumbnail: require('../../images/photos/005-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "fa",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'fa'
    },
    {
        original: require('../../images/photos/006.jpg'),
        thumbnailAlt: 'galamb',
        thumbnail: require('../../images/photos/006-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "galamb",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'galamb'
    },
    {
        original: require('../../images/photos/007.jpg'),
        thumbnailAlt: 'tisza',
        thumbnail: require('../../images/photos/007-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "tisza",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'tisza'
    },
    {
        original: require('../../images/photos/004.jpg'),
        thumbnailAlt: 'kutya',
        thumbnail: require('../../images/photos/004-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "kutya",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'kutya'
    },
    {
        original: require('../../images/photos/008.jpg'),
        thumbnailAlt: 'szobrok',
        thumbnail: require('../../images/photos/008-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "szobrok",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'szobrok'
    },
    {
        original: require('../../images/photos/009.jpg'),
        thumbnailAlt: 'virág',
        thumbnail: require('../../images/photos/009-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "virág",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'virág'
    },
    {
        original: require('../../images/photos/010.jpg'),
        thumbnailAlt: 'galamb',
        thumbnail: require('../../images/photos/010-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "galamb",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'galamb'
    },
    {
        original: require('../../images/photos/013.jpg'),
        thumbnailAlt: 'hajó',
        thumbnail: require('../../images/photos/013-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "hajó",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'hajó'
    },
    {
        original: require('../../images/photos/015.jpg'),
        thumbnailAlt: 'sárkányhajó',
        thumbnail: require('../../images/photos/015-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "sárkányhajó",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'sárkányhajó'
    },
    {
        original: require('../../images/photos/017.jpg'),
        thumbnailAlt: 'galamb',
        thumbnail: require('../../images/photos/017-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "galamb",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'galamb'
    },
    {
        original: require('../../images/photos/016.jpg'),
        thumbnailAlt: 'templom',
        thumbnail: require('../../images/photos/016-s99x66.jpg'),
        thumbnailHeight: 78,
        thumbnailWidth: 117,
        thumbnailLoading: "lazy",
        thumbnailTitle: "templom",
        originalHeight: 570,
        originalWidth: 855,
        loading: "lazy",
        originalTitle: 'templom'
    },
];
/**
  * Képgaléria

  * React-image-gallery könyvtár segítségével
@returns {React.Component} - Photos componens
  * * Validálva
  */
class Photos extends React.Component {
    render() {
        return (
        <ImageGallery 
            items={images} 
            thumbnailPosition='left' 
            showBullets='true'
            autoPlay='true'
            slideInterval='4000'
        />
    )}
}

export default Photos
