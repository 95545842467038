import React, { useState } from 'react'
import '../../index.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { TextField } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import ContactMan from '../../images/contactman.png'
import axios from 'axios'



const validationSchema = Yup.object({
    name: Yup.string().required('Kötelező kitöltendő mező!'),
    email: Yup.string()
        .email('Érvénytelen e-mail formátum!')
        .required('Kötelező kitöltendő mező!'),
    content: Yup.string().required('Kötelező kitöltendő mező!'),
})
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

/**
* E-mail küldő form

* Yup és Formik segítségével validálva
* Material Ui form 
@returns {React.Component} - EmailSender componens
* * Validálva
*/
function EmailSender() {
    const formik = useFormik({
      initialValues: {
            name: '',
            email: '',
            content: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          handleAxios(JSON.stringify(values));
          formik.values.name = ""
          formik.values.email = ""
          formik.values.content = ""
        
      },

    });
    const [open, setOpen] = useState(false);
    const [transition, setTransition] = useState(undefined);
    const [snackbar, setSnackbar] = useState(null)

    const handleClick = (Transition) => () => {
        setTransition(() => Transition);
        setOpen(true);
      };
      const handleAxios = (values) => {
        axios.post('https://www.rehaktamas-portfolio.hu/api/submit-message', {
          "name": formik.values.name,
          "email": formik.values.email,
          "content": formik.values.content
      })
        .then(function (response) {
          //console.log(response.data);
          setSnackbar("success")
        })
        .catch(function (error) {
          //console.log(error);
          setSnackbar("error")
        })
        .finally(function () {
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("content").value = "";
          
        })
      }
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
    const action = (
        <React.Fragment>
          <Button 
            color="secondary" 
            size="small" 
            onClick={handleClose}>
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
      function TransitionUp(props) {
        return <Slide {...props} direction="up" />;
      }
      
    
    return (
      <div className='emailSender row m-0'>
        <form 
          onSubmit={formik.handleSubmit}
          className='col-10 col-xl-8 mt-5 mx-auto p-2 row'>
            <h4 className='pt-2 email-title text-center'>
              E-mail küldés:
            </h4>
            <div className='firstBackground pt-2 mt-2 
                            contact-border'>
              <TextField
                id="name"
                name="name"
                label="Név"
                className='col-12 pb-2 '
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className='firstBackground pt-2 mt-4 
                            contact-border'>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                className='col-12 pb-2'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                />
            </div>
            <div className='firstBackground contact-border pt-2 mt-4'>
              <TextField
                id="content"
                name="content"
                label="Üzenet"
                className='col-12 pb-2 firstbackground'
                value={formik.values.content}
                onChange={formik.handleChange}
                error=
                {formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
                multiline
                rows={6}
                />
                </div>
                <button 
                    className="btn2 my-4 mx-auto"
                    onClick={handleClick(TransitionUp)}
                    type="submit">
                    <span className="icon">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="text">Küldés</span>
                </button>
                {snackbar !== null ? (
                  <Snackbar
                  open={open}
                  autoHideDuration={3000}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right', }}
                  onClose={handleClose}
                  TransitionComponent={transition}
                  action={action}>
                  <Alert 
                    onClose={handleClose} 
                    severity={snackbar} 
                    sx={{ width: '20%' }}>
                      {snackbar === "success" ? "Az üzenet elküldve!" 
                      : "Valami hiba történt! Kérem próbálja meg újra."
                      }
                  </Alert>
                </Snackbar>
                ) : (
                  <div></div>
                )}
        </form>
          <img
            height="700"
            width="346"
            loading='lazy'
            title='contactman'
            className="img-fluid contactman d-none d-xl-block" 
            src={ContactMan} 
            alt="contactman" />
      </div>
    )
}

export default EmailSender
